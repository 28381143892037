exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colorado-js": () => import("./../../../src/pages/colorado.js" /* webpackChunkName: "component---src-pages-colorado-js" */),
  "component---src-pages-contact-a-rep-js": () => import("./../../../src/pages/contact-a-rep.js" /* webpackChunkName: "component---src-pages-contact-a-rep-js" */),
  "component---src-pages-dosing-administration-js": () => import("./../../../src/pages/dosing-administration.js" /* webpackChunkName: "component---src-pages-dosing-administration-js" */),
  "component---src-pages-efficacy-blockade-of-rewarding-effects-js": () => import("./../../../src/pages/efficacy/blockade-of-rewarding-effects.js" /* webpackChunkName: "component---src-pages-efficacy-blockade-of-rewarding-effects-js" */),
  "component---src-pages-efficacy-clinical-safety-js": () => import("./../../../src/pages/efficacy/clinical-safety.js" /* webpackChunkName: "component---src-pages-efficacy-clinical-safety-js" */),
  "component---src-pages-efficacy-phase-3-study-js": () => import("./../../../src/pages/efficacy/phase-3-study.js" /* webpackChunkName: "component---src-pages-efficacy-phase-3-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-your-practice-js": () => import("./../../../src/pages/list-your-practice.js" /* webpackChunkName: "component---src-pages-list-your-practice-js" */),
  "component---src-pages-oud-opioid-use-disorder-js": () => import("./../../../src/pages/oud-opioid-use-disorder.js" /* webpackChunkName: "component---src-pages-oud-opioid-use-disorder-js" */),
  "component---src-pages-pharmacology-buprenorphine-without-daily-fluctuations-js": () => import("./../../../src/pages/pharmacology/buprenorphine-without-daily-fluctuations.js" /* webpackChunkName: "component---src-pages-pharmacology-buprenorphine-without-daily-fluctuations-js" */),
  "component---src-pages-pharmacology-importance-of-2-ng-ml-js": () => import("./../../../src/pages/pharmacology/importance-of-2-ng-ml.js" /* webpackChunkName: "component---src-pages-pharmacology-importance-of-2-ng-ml-js" */),
  "component---src-pages-pharmacology-opioid-receptor-occupancy-js": () => import("./../../../src/pages/pharmacology/opioid-receptor-occupancy.js" /* webpackChunkName: "component---src-pages-pharmacology-opioid-receptor-occupancy-js" */),
  "component---src-pages-pharmacology-sustained-release-buprenorphine-js": () => import("./../../../src/pages/pharmacology/sustained-release-buprenorphine.js" /* webpackChunkName: "component---src-pages-pharmacology-sustained-release-buprenorphine-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-support-services-js": () => import("./../../../src/pages/support-services.js" /* webpackChunkName: "component---src-pages-support-services-js" */),
  "component---src-pages-unsubscribe-confirmation-js": () => import("./../../../src/pages/unsubscribe-confirmation.js" /* webpackChunkName: "component---src-pages-unsubscribe-confirmation-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

